/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
import 'bootstrap'

// Fix custom file input issue
$(document).ready(function() {
    $('.custom-file input').on('change', function (e) {
        $(this).next('.custom-file-label').html(e.target.files[0].name);
    });
});

setTimeout(function() {
    $('#flashMessage-fade').fadeOut(1600, "linear");
}, 2000);

(function( $ ) {
    $.fn.bfsFilter = function() {
        const old_url = window.location.href;

        let old_param = old_url.split("?filter=")[1];
        if (old_param)
            this.val(old_param);

        this.change(function (e) {
            const filter = this.value;
            let new_url = old_url.split("?")[0];

            window.location.href = new_url + "?filter=" + filter;
        });
    };
})(jQuery);

$('.js-bfs_filter').bfsFilter();
